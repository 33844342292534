import { css } from "lit";
import "../../../../../../legl-ui/number";
import { Accordion } from "./accordion.js";

class PaymentsAccordion extends Accordion {
  static get styles() {
    return [
      super.styles,
      css`
                :host {
                    text-align: left;
                }
                .summary-text-container {
                    padding: 1rem 0;
                }
            `,
    ];
  }

  static get properties() {
    return {
      currency: { type: String, attribute: "company-currency" },
      locale: { type: String, attribute: "company-locale" },
    };
  }

  updateBetaContent() {
    /*
        Update the betaContent element to match the form values.
        */
    if (this.form) {
      this.betaContentElements.Name.innerText = `${this.formValues.firstName} ${this.formValues.middleName} ${this.formValues.lastName}`;
      this.betaContentElements.email.innerText = this.formValues.email;
      if (this.formValues.invoiceReference)
        this.betaContentElements.invoiceReference.innerText =
          this.formValues.invoiceReference;
      if (this.formValues.matterReference)
        this.betaContentElements.matterReference.innerText =
          this.formValues.matterReference;
      if (this.formValues.customReference)
        this.betaContentElements.customReference.innerText =
          this.formValues.customReference;
      this.betaContentElements.amount.innerHTML =
        this.currency && this.locale
          ? `<legl-currency currency="${this.currency}" locale="${this.locale}">${this.formValues.amount}</legl-currency>`
          : null;
    }
  }

  get betaContentElements() {
    return {
      Name: document.querySelector("[data-details-name]"),
      email: document.querySelector("[data-details-email]"),
      invoiceReference: document.querySelector(
        "[data-details-invoice-reference]",
      ),
      matterReference: document.querySelector(
        "[data-details-matter-reference]",
      ),
      customReference: document.querySelector(
        "[data-details-custom-reference]",
      ),
      amount: document.querySelector("[data-details-amount]"),
      button_amount: document.querySelector("[data-button-amount]"),
    };
  }

  get formValues() {
    return {
      firstName: document.querySelector("#id_first_name").value.trim(),
      middleName: document.querySelector("#id_middle_name").value.trim(),
      lastName: document.querySelector("#id_last_name").value.trim(),
      email: document.querySelector("#id_email").value.trim(),
      invoiceReference: document.querySelector("#id_invoice_reference")
        ? document.querySelector("#id_invoice_reference").value.trim()
        : null,
      matterReference: document.querySelector("#id_matter_reference")
        ? document.querySelector("#id_matter_reference").value.trim()
        : null,
      customReference: document.querySelector("#id_custom_reference")
        ? document.querySelector("#id_custom_reference").value.trim()
        : null,
      amount: document.querySelector("#id_amount").modelValue,
    };
  }

  attributeChangedCallback(name, newValue, oldValue) {
    super.attributeChangedCallback(name, newValue, oldValue);
    this.updateBetaContent();
  }
}

if (!customElements.get("legl-payments-accordion")) {
  customElements.define("legl-payments-accordion", PaymentsAccordion);
}
