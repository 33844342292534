import { LitElement, css, html } from "lit";
import "../../../legl-ui/lds-button";
import { LdsModal } from "../../../legl-ui/lds-modal";
import { getCookie } from "../../../legl-ui/utils";
import "./cookie_consent_modal";

export class CookieConsent extends LitElement {
  static get styles() {
    return css`
            .cookie-button {
                border: none;
                background: transparent;
                font-family: inherit;
                font-size: inherit;
                color: inherit;
                cursor: pointer;
                padding: 0;
            }
        `;
  }

  static get properties() {
    return {};
  }

  constructor() {
    super();
  }

  openModal() {
    LdsModal.create({
      title: "Cookie consent",
      slotComponent: "legl-cookie-consent-modal",
    });
  }

  async connectedCallback() {
    super.connectedCallback();
    if (getCookie("cookiesAccepted") === undefined) {
      this.openModal();
    } else if (getCookie("cookiesAccepted") === "true") {
      window.posthog?.opt_in_capturing();
    }
  }

  render() {
    return html`<button class="cookie-button" @click=${this.openModal}>
            Cookies
        </button>`;
  }
}

if (!customElements.get("legl-cookie-consent")) {
  customElements.define("legl-cookie-consent", CookieConsent);
}
